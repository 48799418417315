import * as React from "react";
import * as _ from "lodash";
import {
  CircularProgress,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { IGridModel, IResultBase } from "Services/interfaces";
import { GridOrderDirection, ApiResultStatus } from "Utils/enums";
import {
  IGridTableProps,
  IGenerateSortParamsResult,
  IGridTableState,
} from "./interfaces";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableState,
  MUIDataTableColumn,
} from "mui-datatables";
import BlockUi from "react-block-ui";
import { useTranslation } from "react-i18next";

function GridTable<
  paramT extends IGridModel,
  dataT extends object,
  responseT extends IResultBase
>(props: IGridTableProps<paramT, dataT, responseT>): JSX.Element {
  const theme: Theme = useTheme();
  const matches: boolean = useMediaQuery(theme.breakpoints.up("lg"));
  const [reloadCount, setReloadCount] = React.useState(0);
  const tableStateReducer: (
    tableState: IGridTableState<dataT>,
    newTableState: IGridTableState<dataT>
  ) => IGridTableState<dataT> = (
    tableState: IGridTableState<dataT>,
    newTableState: IGridTableState<dataT>
  ): IGridTableState<dataT> => {
    return { ...tableState, ...newTableState };
  };
  const [tableState, dispatchTableState] = React.useReducer(tableStateReducer, {
    columnSortDirection: (props.columnsSortableNames
      ? _.fill(Array(props.columnsSortableNames.length), "none")
      : []) as ("none" | "asc" | "desc" | undefined)[],
    options: {
      pageLength: 10,
      pageNo: 0,
      search: "",
      orderDirection: GridOrderDirection.Desc,
      orderColumn: props.defaultOrderColumn ?? 1,
    },
  });
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState<dataT[]>([]);
  const [selectedTableData, setSelectedTableData] = React.useState<number[]>(
    []
  );
  const [totalRecords, setTotalRecords] = React.useState(10);
  const { t } = useTranslation(["commonResources"]);

  const handleTableChange: () => Promise<void> = async (): Promise<void> => {
    if (reloadCount === 0) {
      setLoading(true);
    }
    let params: paramT | IGridModel = { ...tableState.options };
    if (props.generateParams) {
      params = props.generateParams(params);
    }
    if (props.setSearch) {
      props.setSearch(params.search ?? "");
    }
    await props
      .getList(params)
      .then((response: IResultBase) => {
        if (response && response.status === ApiResultStatus.Ok) {
          const newData = (response as responseT).data.gridData as dataT[];
          if (tableData.length === 0) {
            setSelectedTableData(_.map([...newData.keys()], (a) => a));
          } else if(tableData.length !== newData.length) {
            const newDataRows = _.xor(_.map([...newData.keys()], (a) => a), _.map([...tableData.keys()], (a) => a));
            setSelectedTableData(_.union([...selectedTableData], newDataRows));
          }
          setTableData(newData);
          if(props.handleRowSelectedChange){
            props.handleRowSelectedChange(newData);
          }
          setTotalRecords((response as responseT).data.recordsTotal);
        }
      })
      .finally(() => {
        if (reloadCount === 0) {
          setLoading(false);
        }
      });
  };

  const options: MUIDataTableOptions = {
    count: totalRecords,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    page: tableState.options?.pageNo,
    responsive: "standard",
    searchText: tableState.options?.search,
    serverSide: true,
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50, 100, 10000],
    rowsSelected: selectedTableData,
    renderExpandableRow: (rowData, rowMeta) => {
      if (props.generateExpandedRowContent) {
        const colSpan: number = rowData.length + 1;
        return props.generateExpandedRowContent(
          tableData[rowMeta.dataIndex],
          colSpan
        );
      }
    },
    textLabels: {
      body: {
        noMatch: t("gridForm.body.noMatch"),
        toolTip: "",
      },
      filter: {
        all: t("gridForm.filter.all"),
        reset: t("gridForm.filter.reset"),
        title: t("gridForm.filter.title"),
      },
      pagination: {
        displayRows: t("gridForm.pagination.displayRows"),
        next: t("gridForm.pagination.next"),
        previous: t("gridForm.pagination.previous"),
        rowsPerPage: matches ? t("gridForm.pagination.rowsPerPage") : "",
      },
      selectedRows: {
        delete: t("gridForm.selectedRows.delete"),
        deleteAria: t("gridForm.selectedRows.deleteAria"),
        text: t("gridForm.selectedRows.text"),
      },
      toolbar: {
        downloadCsv: t("gridForm.toolbar.downloadCsv"),
        filterTable: t("gridForm.toolbar.filterTable"),
        print: t("gridForm.toolbar.print"),
        search: t("gridForm.toolbar.search"),
        viewColumns: t("gridForm.toolbar.viewColumns"),
      },
      viewColumns: {
        title: t("gridForm.viewColumns.title"),
        titleAria: t("gridForm.viewColumns.title"),
      },
    },
    ...props.customTableOptions,
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[],
      rowsSelected?: any[]
    ) => {
      if (props.handleRowSelectedChange) {
        let selectedData: dataT[] = [];
        _.each(rowsSelected, (a) => {
          selectedData.push(tableData[a]);
        });
        setSelectedTableData(_.map(allRowsSelected, (a) => a.dataIndex));
        props.handleRowSelectedChange(selectedData);
      }
    },
    onColumnSortChange: (changedColumn: string, direction: string) => {
      if (props.generateSortParams) {
        let newColumnSortDirections: ("none" | "asc" | "desc" | undefined)[] =
          _.fill(Array(tableState.columnSortDirection?.length), "none");
        let newSortDirection: "none" | "asc" | "desc" | undefined = "desc";
        let newTableOption: IGridModel = { ...tableState.options };
        switch (direction) {
          case "descending":
            newSortDirection = "desc";
            newTableOption.orderDirection = GridOrderDirection.Desc;
            break;
          case "ascending":
          default:
            newSortDirection = "asc";
            newTableOption.orderDirection = GridOrderDirection.Asc;
            break;
        }
        let sortParamsResult: IGenerateSortParamsResult =
          props.generateSortParams(changedColumn);
        newColumnSortDirections[sortParamsResult.columnIndex] =
          newSortDirection;
        newTableOption.orderColumn = sortParamsResult.orderColumn;
        let newTableState: IGridTableState<dataT> = {
          columnSortDirection: newColumnSortDirections,
          options: newTableOption,
        };
        dispatchTableState(newTableState);
        setReloadCount(reloadCount + 1);
      }
    },
    onTableChange: (action: string, muiTableState: MUIDataTableState) => {
      if (["changeRowsPerPage", "changePage", "search"].indexOf(action) > -1) {
        let newTableOption: IGridModel = { ...tableState.options };
        let newTableState: IGridTableState<dataT> = {
          options: newTableOption,
        };
        switch (action) {
          case "changeRowsPerPage":
            newTableOption.pageLength = muiTableState.rowsPerPage;
            newTableState.options = newTableOption;
            dispatchTableState(newTableState);
            setReloadCount(reloadCount + 1);
            break;
          case "changePage":
            newTableOption.pageNo = muiTableState.page;
            newTableState.options = newTableOption;
            dispatchTableState(newTableState);
            setReloadCount(reloadCount + 1);
            break;
          case "search":
            newTableOption.search = muiTableState.searchText ?? "";
            newTableState.options = newTableOption;
            dispatchTableState(newTableState);
            setReloadCount(reloadCount + 1);
            break;
        }
      }
    },
  };

  React.useEffect(() => {
    handleTableChange();
  }, [reloadCount]);

  React.useEffect(() => {
    if (props.reloadCount && reloadCount !== props.reloadCount) {
      setReloadCount(props.reloadCount);
    }
  }, [props.reloadCount]);

  const generateColumns: () => MUIDataTableColumn[] =
    (): MUIDataTableColumn[] => {
      let newTableColumns: MUIDataTableColumn[] = [...props.columns];
      if (
        tableState.columnSortDirection &&
        tableState.columnSortDirection.length > 0
      ) {
        let index: number = 0;
        _.forEach(props.columnsSortableNames, (value: string) => {
          let columnIndex: number = _.findIndex(
            newTableColumns,
            (tc: MUIDataTableColumn) => {
              return tc.name === value;
            }
          );
          if (columnIndex >= 0) {
            newTableColumns[columnIndex].options = {
              ...newTableColumns[columnIndex].options,
              sortDirection: tableState.columnSortDirection
                ? tableState.columnSortDirection[index]
                : "none",
            };
            index++;
          }
        });
      }
      return newTableColumns;
    };

  const columns: MUIDataTableColumn[] = generateColumns();

  return (
    <React.Fragment>
      <BlockUi
        blocking={loading}
        loader={<CircularProgress color="secondary" />}
      >
        <MUIDataTable
          title=""
          data={tableData}
          columns={columns}
          options={options}
        />
      </BlockUi>
    </React.Fragment>
  );
}

export default GridTable;
